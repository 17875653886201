import QuickActionBar_anchoredSkinComponent from '@wix/thunderbolt-elements/src/components/QuickActionBar/viewer/skinComps/AnchoredSkin/anchoredSkin.skin';
import QuickActionBar_anchoredSkinController from '@wix/thunderbolt-elements/src/components/QuickActionBar/viewer/QuickActionBar.controller';


const QuickActionBar_anchoredSkin = {
  component: QuickActionBar_anchoredSkinComponent,
  controller: QuickActionBar_anchoredSkinController
};


export const components = {
  ['QuickActionBar_anchoredSkin']: QuickActionBar_anchoredSkin
};

